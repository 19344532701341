var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { useAppDispatch } from "../../../../../redux/store";
import style from "./formStyles.module.css";
import { useSelector } from "react-redux";
import { setDataToUpdate, setUserUpdateField } from "../../../../../redux/updateUserReducer";
export var DiscontForm = function () {
    var _a, _b, _c, _d, _e;
    var company = useSelector(function (state) { return state.auth.userAuthInfo; });
    var userData = useSelector(function (state) { var _a; return (_a = state.updateUser) === null || _a === void 0 ? void 0 : _a.dataToUpdate; });
    var isUpdatingFormActivated = useSelector(function (state) { return state.updateUser.isUpdatingFormActivated; });
    useEffect(function () {
        var _a, _b, _c;
        if (!isUpdatingFormActivated) {
            var currentData = {
                about: {
                    info: {
                        name: (_a = company === null || company === void 0 ? void 0 : company.info) === null || _a === void 0 ? void 0 : _a.name,
                        status: (_c = (_b = company === null || company === void 0 ? void 0 : company.info) === null || _b === void 0 ? void 0 : _b.status) !== null && _c !== void 0 ? _c : "",
                    },
                    email: company === null || company === void 0 ? void 0 : company.email,
                },
            };
            dispatch(setDataToUpdate(currentData));
        }
    }, []);
    var dispatch = useAppDispatch();
    // * ---------------- CHANGERS ----------------------- * //
    var onChangeUserName = function (value) {
        dispatch(setUserUpdateField({ field: "email", value: value }));
    };
    var onChangeName = function (value) {
        dispatch(setUserUpdateField({ field: "name", value: value }));
    };
    var onChangeAbout = function (value) {
        dispatch(setUserUpdateField({ field: "status", value: value }));
    };
    // * ---------------- CHANGERS ----------------------- * //
    return (_jsxs("div", __assign({ className: style.formWrapper }, { children: [_jsxs("div", __assign({ className: style.labelAndInputWrapper }, { children: [_jsx("label", __assign({ htmlFor: "email" }, { children: "Email" })), _jsx("input", { disabled: true, className: style.inputNotActive, value: (_a = userData === null || userData === void 0 ? void 0 : userData.about) === null || _a === void 0 ? void 0 : _a.email, onChange: function (e) { return onChangeUserName(e.target.value); } })] })), _jsxs("div", __assign({ className: style.labelAndInputWrapper }, { children: [_jsx("label", __assign({ htmlFor: "name" }, { children: "\u0418\u043C\u044F" })), _jsx("input", { id: "name", className: style.inputInfoForm, value: (_c = (_b = userData === null || userData === void 0 ? void 0 : userData.about) === null || _b === void 0 ? void 0 : _b.info) === null || _c === void 0 ? void 0 : _c.name, onChange: function (e) { return onChangeName(e.target.value); } })] })), _jsxs("div", __assign({ className: style.labelAndInputWrapper }, { children: [_jsx("label", __assign({ htmlFor: "status" }, { children: "\u041E \u0434\u0438\u0441\u043A\u043E\u043D\u0442\u0435" })), _jsx("input", { id: "status", className: style.inputInfoForm, value: (_e = (_d = userData === null || userData === void 0 ? void 0 : userData.about) === null || _d === void 0 ? void 0 : _d.info) === null || _e === void 0 ? void 0 : _e.status, onChange: function (e) { return onChangeAbout(e.target.value); } })] }))] })));
};
